import React from "react";
import PropTypes from "prop-types";
import i18n from "../../../../i18n";
import Modal from "../../../../components/Modal/Modal";
import ShareContainerView from "../ShareContainerView";
import {hitEvent, hits} from "../../../../utils/log";
import { ModalContainerStyled, ModalBlockStyled, ModalStarIcon1, ModalStarIcon3, ModalStarIcon4, ModalStarIcon5, ModalStarIcon2 } from "./ShareModal.style";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import Button from "../../../../components/Button/Button";


export default class ShareModal extends Modal {

  constructor(props) {
    super(props);
    this.className = "share-modal";
  }

  componentDidMount() {
    if (window.clientConfig.isWebview) {
      window.webviewEventsListeners.backPress.push(() => {
        this.dismiss();
        return true;
      });
    }
  }

  dismiss(trigger) {
    if (window.clientConfig.isWebview) {
      window.webviewEventsListeners.backPress.pop();
    }

    super.dismiss(trigger);
  }

  handleGoToStart = (e) => {
    this.dismiss();

    this.props.onGoToStart();
  };

  handleCancelButtonClick = () => {
    hitEvent(hits.SHARE_MODAL_CANCEL_CLICK);

    this.dismiss();
  }

  renderIos = () => {
    return <ModalContainerStyled>
      <ModalBlockStyled margin>
        <MainTitle>
          {i18n.t("share_modal__saved_to_gallery")}
          <ModalStarIcon1 children={<SvgStarIcon />} />
          <ModalStarIcon2 children={<SvgStarIcon />} />
          <ModalStarIcon3 children={<SvgStarIcon />} />
          <ModalStarIcon4 children={<SvgStarIcon />} />
          <ModalStarIcon5 children={<SvgStarIcon />} />
        </MainTitle>
      </ModalBlockStyled>

      <ModalBlockStyled>
        <MainTitle>
          <span dangerouslySetInnerHTML={{__html: i18n.t("share_modal__title")}} />
        </MainTitle>
        <p dangerouslySetInnerHTML={{__html: i18n.t("share_modal__text1")}} />
        <ShareContainerView
          hidden={window.clientConfig.isWebviewIOS}
          providers={this.props.providers}
          onShare={this.props.onShare}
        />
      </ModalBlockStyled>

      <Button primary onClick={this.handleGoToStart} dangerouslySetInnerHTML={{__html: i18n.t("share_modal__start_again")}} />
    </ModalContainerStyled>
  };

  renderAndroid = () => {
    return <React.Fragment>
      <div className="popup-block popup-block-title">
        <h2 className="result-page-title">
          Saved to gallery!
          <SvgStarIcon class="icon-star icon-star1" />
          <SvgStarIcon class="icon-star icon-star2" />
          <SvgStarIcon class="icon-star icon-star3" />
          <SvgStarIcon class="icon-star icon-star4" />
          <SvgStarIcon class="icon-star icon-star5" />
        </h2>

        <h2 className="popup-title" dangerouslySetInnerHTML={{__html: i18n.t("share_modal__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("share_modal__text1")}} />
        <ShareContainerView
          providers={this.props.providers}
          onShare={this.props.onShare}
        />
        <button
          className="btn-close-popup"
          onClick={this.handleCancelButtonClick}
          dangerouslySetInnerHTML={{__html: i18n.t("share_modal__close")}} />
      </div>
      <button className="btn-buy" onClick={this.handleGoToStart} dangerouslySetInnerHTML={{__html: i18n.t("share_modal__start_again")}} />
    </React.Fragment>;
  };

  renderModal() {
    return this.renderIos();
    // return window.clientConfig.isWebviewAndroid
    //   ? this.renderAndroid()
    //   : this.renderIos();
  }
}

ShareModal.propTypes = {
  onShare: PropTypes.func.isRequired,
};

function SvgStarIcon(props) {
  return <svg className={props.class} viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>;
}