import styled from "styled-components";

export const TestInstanceToastStyled = styled.div`
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  z-index: 65535;
  padding: 12px 16px;
  background-color: rgba(255, 255, 255, .8);
  color: black;
  font-weight: 700;
  border-radius: 6px;
  box-shadow: 0 2px 14px 2px rgba(0, 0, 0, .25);
  text-align: center;
`