import React from "react";
import AppContext from "../../../contexts/AppContext";
import routes from "../../../routes";
import creativeGroups from "../../../photolab/config/groups";
import Processing from "../../../photolab/Processing"
import Creative from "../../../photolab/Creative";
import {resetNativeAdsCounter} from "../../../utils/native-ads";
import clientStorage from "../../../utils/client-storage";
import processingManager from "../../../photolab/ProcessingManager";
import {debounce} from "../../../utils/etc";
import ErrorView from "../shared/ErrorView/ErrorView";
import {logEvent, userEvents} from "../../../utils/log";

export default class ProcessingPage extends React.Component {

  state = {
    error: null,
  };

  processingTimerId = null;
  processingTimer = 0;

  componentDidMount() {
    document.addEventListener("visibilitychange", this.startProcessingTimer, false);
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChange);

    const processing = processingManager.processing ?? processingManager.restore();

    if (processing) {
      processingManager.start(processing);
      this.startProcessingTimer();
    } else {
      this.props.history.replace(routes.BOUNTY_INDEX);
    }
  }

  componentWillUnmount() {
    this.stopProcessingTimer();

    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChange);
  }

  stopProcessingTimer = () => {
    clearInterval(this.processingTimerId);
    document.removeEventListener("visibilitychange", this.startProcessingTimer, false);
  }

  startProcessingTimer = () => {
    clearInterval(this.processingTimerId);

    if (document.visibilityState !== "visible") {
      return;
    }

    this.processingTimerId = setInterval(() => {
      this.processingTimer++;

      if ((this.processingTimer * 1000) > window.appConfig.processings.timeout) {
        this.stopProcessingTimer();
        this.handleProcessingTimeout();
      }
    }, 1000);
  }

  // startProcessing = (file, creativeConfig) => {
  //   const groups = [creativeConfig.group];
  //
  //   const processing = new Processing();
  //   processing.setId(Date.now());
  //   processing.setFile(file);
  //   processing.setGroups(groups);
  //   processing.setLanguage(window.clientConfig.lang);
  //   processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
  //
  //   // getCreativesConfigs()
  //   //   .filter((config) => config.group === creativeGroups.COMMON)
  //   //   .forEach((config) => {
  //   //     processing.addCreative(new Creative()
  //   //       .configureByConfig(config));
  //   //   });
  //
  //   processing.addCreative(
  //     new Creative()
  //       .configureByConfig(creativeConfig)
  //       .setAsSelected(true)
  //   );
  //
  //   const sessionId = parseInt(window.clientConfig.webviewParams.session_idx || 0);
  //   const [storedSessionId, storedCount] = clientStorage.getCountProcessingInSession();
  //   const processingNumber = sessionId !== storedSessionId ? 1 : (storedCount + 1);
  //   clientStorage.setCountProcessingInSession(sessionId, processingNumber);
  //
  //   processingManager.start(processing);
  //
  //   this.startProcessingTimer();
  //
  //   // showNativeAds();
  // };

  /** @param {Processing} processing */
  // addCreatives = (processing) => {
  //   const startKeepPendingIndex = (window.clientConfig.splitGroupId < 11) ? 1 : 0; // true
  //
  //   processing.groups.forEach((group, index) => {
  //     const selectedCreativeConfig = resolveCreativeConfigByGroup(group, processing.getGender());
  //     if (!selectedCreativeConfig) {
  //       return;
  //     }
  //
  //     const selectedCreative = new Creative()
  //       .configureByConfig(selectedCreativeConfig)
  //       .setAsSelected(true);
  //
  //     const addCreatives = [];
  //     addCreatives.push(selectedCreative);
  //
  //     getCreativesConfigsByGroup(group)
  //       .filter((c) => c.templateId !== selectedCreativeConfig.templateId)
  //       .forEach((creativeConfig) => {
  //         addCreatives.push(new Creative().configureByConfig(creativeConfig));
  //       })
  //
  //     addCreatives.forEach((c, ci) => {
  //       let keepPending = !window.clientConfig.isPro;
  //       if (c.isSelected) {
  //         keepPending = false;
  //
  //         if (!window.clientConfig.features.isAutoStartCreatives && !window.clientConfig.isPro) {
  //           keepPending = index > startKeepPendingIndex;
  //         }
  //       }
  //
  //       if (keepPending) {
  //         c.setExtra(Creative.EXTRA_KEEP_PENDING, true);
  //       }
  //
  //       c.setAsRefreshed(ci > 0);
  //
  //       processing.addCreative(c);
  //
  //       const creativePreviewImageUrl = resolveCreativePreviewFile(c);
  //       if (creativePreviewImageUrl) {
  //         (new Image()).src = creativePreviewImageUrl;
  //       }
  //     });
  //   });
  //
  //   processingManager.update();
  // }

  /** @param {Processing} processing */
  handleProcessingChange = (processing) => debounce("ProcessingPage_handleProcessingChange", 100, () => {
    if (window.appConfig.isDebug) {
      console.info("ProcessingPage::handleProcessingChange", JSON.parse(processing.toJSON()));
    }

    const selectedAndStartedCreatives = processing.creatives.filter((c) => {
      return c.isSelected
        && c.getExtra(Creative.EXTRA_KEEP_PENDING, false) !== true
        && c.group !== creativeGroups.COMMON
      ;
    });

    if (selectedAndStartedCreatives.length === 0) {
      //this.addCreatives(processing);
      return;
    }

    const processedCreatives = selectedAndStartedCreatives.filter((c) => c.isProcessed);
    const failedCreatives = selectedAndStartedCreatives.filter((c) => c.isFailed);

    if (processedCreatives.length > 0) {
      this.stopProcessingTimer();

      clientStorage.incrementProcessedPhotosAmount();
      if (!clientStorage.hasFirstProcessingProcessedAt()) {
        clientStorage.setFirstProcessingProcessedAt(Date.now());
      }

      const elapsedMs = Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT);

      // hitEvent(hits.PROCESSING_PROCESSED);
      logEvent(userEvents.PROCESSING_PROCESSED, {elapsed_time_ms: elapsedMs});
      // logProcessingsTimings(elapsedMs);

      this.props.history.replace({pathname: routes.BOUNTY_RESULT});
    }
    else if (failedCreatives.length === selectedAndStartedCreatives.length) {
      this.stopProcessingTimer();

      const failedCreative = failedCreatives[0];

      // hitEvent(hits.PROCESSING_FAILED);
      logEvent(userEvents.PROCESSING_FAILED, {
        elapsed_time_ms: Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT),
      });

      if (failedCreative.error && failedCreative.error.type === "photolab") {
        // hitEvent(hits.PROCESSING_FAILED_BY_PHOTOLAB);
        resetNativeAdsCounter();
      } else {
        // processingManager.commitToApi(processing.toJSON());
      }

      processingManager.clear();
      this.context.hideLoader();

      this.setState({
        error: failedCreative.error,
      });
    }
  });

  handleProcessingTimeout = () => {
    processingManager.clear();
    this.context.hideLoader();

    this.setState({
      error: {
        type: "processing_timeout",
        code: 1,
        message: "timeout",
      },
    });
  };

  handleGoToCreate = () => {
    if (this.props.location.state && this.props.location.state.profile) {
      this.props.history.replace({
        pathname: routes.BOUNTY_CREATE,
        state: {profile: this.props.location.state.profile},
      });
    } else {
      this.props.history.replace(routes.BOUNTY_INDEX);
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onBack={this.handleGoToCreate}
        onClose={this.handleGoToCreate}
      />;
    }

    return <React.Fragment />;
  }
}

ProcessingPage.contextType = AppContext;
