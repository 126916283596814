import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import routes from "./routes";
import GlobalStyles from "./globalStyles"
import ModalWrapper from "./components/ModalWrapper";
import Loader from "./components/Loader/Loader";
import { AppContextProvider } from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import { logEvent, userEvents } from "./utils/log";
import * as webviewUtils from "./utils/webview";
import ErrorBoundary from "./components/ErrorBoundary";
import TestInstanceToast from "./components/TestInstanceToast/TestInstanceToast";
import WindowSizeWatcher from "./components/WindowSizeWatcher";

import BountyIndexPage from "./pages/bounty/index/IndexPage";
import BountyTermsPage from "./pages/bounty/terms/TermsPage";
import BountySignupPage from "./pages/bounty/signup/SignupPage";
import BountyCreatePage from "./pages/bounty/create/CreatePage";
import BountyProcessingPage from "./pages/bounty/processing/ProcessingPage";
import BountyResultPage from "./pages/bounty/result/ResultPage";
import BountyRewardPage from "./pages/bounty/reward/RewardPage";
import RouteChangeListener from "./components/RouteChangeListener";

window.onerror = function (message, file, line, col, error) {
  logEvent(userEvents.JS_GLOBAL_ERROR, {
    message,
    file,
    line,
    col,
    error: error.message,
    url: window.location.href,
  });
};

const LazyIndexPage = React.lazy(() => import("./pages/IndexPage"));
const LazyFeedPage = React.lazy(() => import("./pages/FeedPage"));
const LazyUploadPage = React.lazy(() => import("./pages/UploadPage"));
const LazyProcessingPage = React.lazy(() => import("./pages/ProcessingPage"));
const LazyMaskEditorPage = React.lazy(() => import("./pages/MaskEditorPage"));
const LazyResultPage = React.lazy(() => import("./pages/result/ResultPage"));
const LazyErrorPage = React.lazy(() => import("./pages/ErrorPage"));

class App extends React.Component {

  componentDidMount() {
    // window.clientConfig.platform.os.toLowerCase() === "ios" && document.body.classList.add("ios");
    window.clientConfig.isWebview && document.body.classList.add("webview");
    document.body.classList.add(`app-lang--${window.clientConfig.lang}`);
    // используем дизайн для ios
    document.body.classList.add("app-webview--ios");

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewOnPageFinished();
    }

    document.body.addEventListener("mousewheel", (e) => {
      // отключаем скейлинг страницы по ctrl+scroll
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, { passive: false });
  }

  render() {
    return (
      <div>
        <WindowSizeWatcher />
        <TestInstanceToast />
        <ErrorBoundary>
          <BrowserRouter>
            <RouteChangeListener onChanged={() => window.scrollTo(0, 0)} />
            <Suspense fallback={<div />}>
              <Loader {...this.context.loader} />
              <Switch>
                <Route exact path={routes.INDEX} render={(props) => <LazyIndexPage {...props} />} />
                <Route exact path={routes.FEED} render={(props) => <LazyFeedPage {...props} />} />
                <Route exact path={routes.UPLOAD} render={(props) => <LazyUploadPage {...props} />} />
                <Route exact path={routes.PROCESSING} render={(props) => <LazyProcessingPage {...props} />} />
                <Route exact path={routes.MASK_EDITOR} render={(props) => <LazyMaskEditorPage {...props} />} />
                <Route exact path={routes.RESULT} render={(props) => <LazyResultPage {...props} />} />
                <Route exact path={routes.ERROR} render={(props) => <LazyErrorPage {...props} />} />

                <Route exact path={routes.BOUNTY_INDEX} render={(props) => <BountyIndexPage {...props} />} />
                <Route exact path={routes.BOUNTY_TERMS} render={(props) => <BountyTermsPage {...props} />} />
                <Route exact path={routes.BOUNTY_SIGNUP} render={(props) => <BountySignupPage {...props} />} />
                <Route exact path={routes.BOUNTY_CREATE} render={(props) => <BountyCreatePage {...props} />} />
                <Route exact path={routes.BOUNTY_PROCESSING} render={(props) => <BountyProcessingPage {...props} />} />
                <Route exact path={routes.BOUNTY_RESULT} render={(props) => <BountyResultPage {...props} />} />
                <Route exact path={routes.BOUNTY_REWARDS} render={(props) => <BountyRewardPage {...props} />} />
              </Switch>
            </Suspense>
          </BrowserRouter>
          <ModalWrapper />
        </ErrorBoundary>
      </div>
    );
  }
}

App.contextType = AppContext;

const reactRoot = ReactDOM.createRoot(document.getElementById("root"));
reactRoot.render(<AppContextProvider>
  <GlobalStyles />
  <App />
</AppContextProvider>);
