import React from "react";
import FlexWrap from "../../../components/FlexWrap/FlexWrap";
import { SuggectionsViewListItemImageStyled, SuggectionsViewListItemImageContainerStyled, SuggectionsViewListItemParagraphStyled, SuggectionsViewListItemStyled, SuggectionsViewListStyled } from "./CreatePage.style";
import Paragraph from "../../../components/Paragraph/Paragraph";

export default class SuggestionsView extends React.Component {
  convertFollowersCount = (value) => {
    const billion = value / 10 ** 9;
    const million = value / 10 ** 6;
    const thousand = value / 10 ** 3;

    if (billion > 1) {
      return (million).toFixed(1) + 'B';
    } else if (million > 1) {
      return (million).toFixed(1) + 'M';
    } else if (thousand > 1) {
      return (thousand).toFixed(1) + 'K';
    } else {
      return value;
    }
  }

  render() {
    return <SuggectionsViewListStyled>
      {this.props.profiles.map((profile) =>
        <SuggectionsViewListItemStyled
          key={profile.username}
          onClick={() => this.props.onSelected(profile)}
          style={{ cursor: "pointer" }}>
          {profile.avatar_file && <SuggectionsViewListItemImageContainerStyled>
            <SuggectionsViewListItemImageStyled src={profile.avatar_file.url} alt="." />
          </SuggectionsViewListItemImageContainerStyled>}
          
          <FlexWrap direction="column" align="flex-start" justify="center" gap="8">
            <Paragraph className="bold">{profile.username}</Paragraph>
            <FlexWrap gap="8" wrap={1}>
              <SuggectionsViewListItemParagraphStyled>{profile.name}</SuggectionsViewListItemParagraphStyled>
              <SuggectionsViewListItemParagraphStyled>{this.convertFollowersCount(profile.followers)} followers</SuggectionsViewListItemParagraphStyled>
            </FlexWrap>
          </FlexWrap>
        </SuggectionsViewListItemStyled>
      )}
    </SuggectionsViewListStyled>;
  }
}
