import styled from "styled-components";
import FileChooseButton from "../FileChooseButton";

export const FileUploaderStyled = styled.div`
    padding: 12px 16px;
    width: 100%;
    border-radius: 12px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid #000;
    caret-color: #ffcb20;
    display: flex;
    align-items: center;
`;

export const UploadButton = styled(FileChooseButton)`
    ${props => props.primary ?
        `
            margin-right: 20px;
        
            button {
                line-height: 1;
                font-size: 16px;
                font-weight: 600;
                color: #000;
                border: none;
                background-color: #ffcb20;
                padding: 13px 16px;
                border-radius: 12px;
                border: 1px solid #000;
                box-shadow: #000 3px 3px 0px 0px;
                text-decoration: none;
                cursor: pointer;
                transition: background-color .2s ease;
            
                &:hover {
                    background-color: #d5c6f8;
                }
            }
        `
        :
        `
            display: inline;

            button {
                font-weight: 600;
                font-size: 16px;
                line-height: 1.5;
                background: none;
                text-decoration: underline;
            }
        `
    }
`;

