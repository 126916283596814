import React from "react";
import { TestInstanceToastStyled } from "./TestInstanceToast.style"

export default class TestInstanceToast extends React.Component {

  state = {
    hidden: true,
  }

  componentDidMount() {
    this.show();

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.show();
      }
    });
  }

  show = () => {
    if (window.clientConfig.isWeb) {
      return;
    }

    if (!window.appConfig.isTestInstance) {
      return;
    }

    this.setState({hidden: false}, () => {
      setTimeout(() => this.setState({hidden: true}), 5000);
    });
  };

  render() {
    if (this.state.hidden) {
      return <React.Fragment />;
    }

    return <TestInstanceToastStyled
      onClick={() => this.setState({hidden: true})}>
      {new URL(window.location.href).hostname}
    </TestInstanceToastStyled>;
  }
}