import React from "react";
import AppContext from "../../../contexts/AppContext";
import FeedView from "./FeedView";
import ConfigView from "./ConfigView/ConfigView";
import processingManager from "../../../photolab/ProcessingManager";
import routes from "../../../routes";
import Processing from "../../../photolab/Processing";
import Creative from "../../../photolab/Creative";
import FileChooseButton from "../../../components/FileChooseButton";
import uploadHandler from "../../../utils/upload.handler";
import SuggestionsView from "./SuggestionsView";
import * as api from "../../../utils/api";
import CreativeConfig from "../../../photolab/CreativeConfig";
import feedConfigHelper from "../../../helpers/feed-config.helper";
import clientStorage from "../../../utils/client-storage";
import i18n from "../../../i18n";
import MainTitle from "../../../components/MainTitle/MainTitle";
import BackButton from "../../../components/BackButton/BackButton";
import Container from "../../../components/Container/Container";
import FlexWrap from "../../../components/FlexWrap/FlexWrap";
import LoadMoreButton from "../../../components/LoadMoreButton/LoadMoreButton";
import Paragraph from "../../../components/Paragraph/Paragraph";
import ErrorView from "../shared/ErrorView/ErrorView";
import {UploadButton} from "../../../components/FileUploader/FileUploader.style";

const viewMode = {
  profiles: "profiles",
  profileFeed: "profileFeed",
  configs: "configs",
};

const NUMBER_OF_PROFILES_SHOWN = 10;
const NUMBER_OF_PROFILE_FEED_SHOWN = 9;

export default class CreatePage extends React.Component {

  state = {
    feedConfig: [],
    profile: {},
    profileFeed: [],
    error: null,
    selectedImage: null,
    selectedConfig: null,
    viewMode: viewMode.profiles,
    numberOfProfilesShown: NUMBER_OF_PROFILES_SHOWN,
    numberOfProfileFeedShown: NUMBER_OF_PROFILE_FEED_SHOWN,
  };

  componentDidMount() {
    this.context.showLoader();

    const storedInstagramProfiles = clientStorage.getInstagramProfiles();
    const isExpired = !storedInstagramProfiles.created_at || storedInstagramProfiles.created_at + 86400000 <= Date.now();

    const instagramProfilesPromise = !isExpired
      ? Promise.resolve()
      : api.getTopInstagramProfiles()
        .then((result) => {
          clientStorage.setInstagramProfiles(result);
        });

    Promise.all([
      feedConfigHelper.getConfig("tcoinfullsize"),
      instagramProfilesPromise,
    ]).then(([feedConfig]) => {
      this.setState({
        feedConfig
      }, () => {
        if (this.props.location.state && this.props.location.state.profile) {
          this.handleSelectedProfile(this.props.location.state.profile);
        } else {
          this.context.hideLoader();
        }
      });
    }).catch((err) => {
      console.error(err);
      this.context.hideLoader();
    });
  }

  handleSelectedProfile = (profile) => {
    this.context.showLoader();

    api.getInstagramProfileFeed(profile.username)
      .then((result) => {
        this.setState({
          viewMode: viewMode.profileFeed,
          profile: profile,
          profileFeed: result,
        }, this.context.hideLoader);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleShowMoreProfileFeed = () => {
    this.setState({
      numberOfProfileFeedShown: (this.state.numberOfProfileFeedShown + NUMBER_OF_PROFILE_FEED_SHOWN),
    });
  }

  handleShowMoreProfiles = () => {
    this.setState({
      numberOfProfilesShown: (this.state.numberOfProfilesShown + NUMBER_OF_PROFILES_SHOWN),
    });
  }

  handleSelectedFeedImage = (feed) => {
    this.setState({
      selectedImage: { ...feed.file, isCeleb: true },
      viewMode: viewMode.configs,
    }, () => {
      window.scrollTo(0, 0);
    });
  }

  handleFileSelected = (file) => {
    this.context.showLoader();

    uploadHandler(file)
      .then((image) => {
        this.setState({
          selectedImage: image,
          viewMode: viewMode.configs,
        }, this.context.hideLoader);
      })
      .catch((error) => {
        this.setState({
          error: error,
        }, this.context.hideLoader);
      });
  }

  handleSelectedConfig = (config) => {
    this.context.showLoader(() => {
      this.setState({
        selectedConfig: config,
      }, this.startProcessing);
    });
  }

  startProcessing = () => {
    const creative = new Creative();

    if (this.state.selectedConfig) {
      const config = feedConfigHelper.transformConfig(this.state.selectedConfig)
        .setGroup(this.state.selectedConfig.group);

      creative
        .configureByConfig(config)
        .setAsSelected(true);
    } else {
      creative
        .configureByConfig(new CreativeConfig("origin", "origin", "dummy"))
        .setFile("raw", this.state.selectedImage.url)
        .markAsProcessed(this.state.selectedImage.url)
        .setAsSelected(true);
    }

    const processing = new Processing();
    processing.setId(Date.now());
    processing.setFile(this.state.selectedImage);
    processing.setGroups([creative.group]);
    processing.setLanguage(window.clientConfig.lang);
    processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
    processing.setExtra(Processing.EXTRA_IG_PROFILE_USERNAME, this.state.profile.username)
    processing.addCreative(creative);

    processingManager.clear();
    processingManager.setProcessing(processing);

    this.props.history.replace({
      pathname: routes.BOUNTY_PROCESSING,
      state: {profile: this.state.profile},
    });
  };

  handleSkipProcessing = () => {
    this.startProcessing();
  }

  handleGoToStart = () => {
    this.props.history.push(routes.BOUNTY_INDEX);
  }

  handleGoToProfileFeed = () => {
    this.setState({
      viewMode: viewMode.profileFeed,
      error: null,
      selectedImage: null,
    });
  }

  handleGoToProfiles = () => {
    this.setState({
      viewMode: viewMode.profiles,
      profileFeed: [],
      numberOfProfileFeedShown: NUMBER_OF_PROFILE_FEED_SHOWN,
    });
  }

  handleClearError = () => {
    this.setState({
      error: null,
      selectedImage: null,
    });
  }

  renderProfilesMode = () => {
    const { items: storedProfiles } = clientStorage.getInstagramProfiles();
    const profiles = storedProfiles.slice(0, this.state.numberOfProfilesShown);

    return <Container>
      <FlexWrap direction="column" gap="24">
        <BackButton onClick={this.handleGoToStart} />
        <MainTitle dangerouslySetInnerHTML={{ __html: i18n.t("bounty.choose_celebrity_heading") }} />
        <SuggestionsView
          profiles={profiles}
          onSelected={this.handleSelectedProfile}
        />
        <LoadMoreButton
          hidden={profiles.length >= storedProfiles.length}
          onClick={this.handleShowMoreProfiles}>
          {i18n.t("bounty.choose_celebrity_refresh_button")}
        </LoadMoreButton>
      </FlexWrap>
    </Container>;
  }

  renderProfileFeedMode = () => {
    const feed = this.state.profileFeed.slice(0, this.state.numberOfProfileFeedShown);

    return <Container>
      <FlexWrap direction="column" gap="24">
        <BackButton onClick={this.handleGoToProfiles} />
        <MainTitle dangerouslySetInnerHTML={{ __html: i18n.t("bounty.choose_image_heading") }} />

        <Paragraph>
          {i18n.t("bounty.choose_image_feed_text")} <UploadButton
            onFileSelected={this.handleFileSelected}>
            {i18n.t("bounty.choose_image_upload_button")}
          </UploadButton>
        </Paragraph>
        <FeedView
          hidden={feed.isEmpty()}
          feed={feed}
          onSelected={this.handleSelectedFeedImage}
        />
        <LoadMoreButton
          hidden={feed.length >= this.state.profileFeed.length}
          onClick={this.handleShowMoreProfileFeed}>
          {i18n.t("bounty.choose_image_feed_refresh_button")}
        </LoadMoreButton>
      </FlexWrap>
    </Container>;
  }

  renderConfigsMode = () => {
    return <Container>
      <BackButton onClick={this.handleGoToProfileFeed} />
      <MainTitle dangerouslySetInnerHTML={{ __html: i18n.t("bounty.choose_config_heading") }} />

      <p
        hidden={this.state.selectedImage.isCeleb}
        onClick={(e) => {
          e.stopPropagation();
          if (e.target.tagName.toLowerCase() === "a") {
            this.handleSkipProcessing();
          }
        }}
        dangerouslySetInnerHTML={{ __html: i18n.t("bounty.choose_config_text") }}
      />

      <button
        hidden={this.state.selectedImage.isCeleb}
        onClick={this.handleSkipProcessing}>
        {i18n.t("bounty.choose_config_use_original_button")}
      </button>

      <ConfigView
        config={this.state.feedConfig}
        onSelected={this.handleSelectedConfig}
      />
    </Container>;
  }

  render() {
    return <React.Fragment>
      {this.state.error && <ErrorView
        error={this.state.error}
        onBack={this.handleClearError}
        onClose={this.handleClearError}
      />}
      <main className="create-page" hidden={this.state.error}>
        {this.state.viewMode === viewMode.profiles && this.renderProfilesMode()}
        {this.state.viewMode === viewMode.profileFeed && this.renderProfileFeedMode()}
        {this.state.viewMode === viewMode.configs && this.renderConfigsMode()}
      </main>
    </React.Fragment>;
  }
}

CreatePage.contextType = AppContext;
